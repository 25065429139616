import {defineMessages} from 'react-intl.macro';
import {CustomerType, Module} from './state';

export default defineMessages({
  [Module.AUTOMATED_MONITORING]: {
    defaultMessage: 'AM',
    id: 'customers.am'
  },
  [Module.WORK_MANAGEMENT]: {
    defaultMessage: 'WM',
    id: 'customers.wm'
  },
  [Module.AUTOMATED_MONITORING + '.name']: {
    defaultMessage: 'Automated Monitoring',
    id: 'customers.am.name'
  },
  [Module.WORK_MANAGEMENT + '.name']: {
    defaultMessage: 'Work Management',
    id: 'customers.wm.name'
  },
  [CustomerType.LIVE]: {id: 'customers.type.live', defaultMessage: 'Live'},
  [CustomerType.DORMANT]: {id: 'customers.type.dormant', defaultMessage: 'Dormant'},
  [CustomerType.INTERNAL]: {id: 'customers.type.internal', defaultMessage: 'Internal'},
  [CustomerType.TRIAL]: {id: 'customers.type.trial', defaultMessage: 'Trial'},
  'customers.actions': {id: 'customers.actions', defaultMessage: 'Actions'},
  'customers.addChildCustomer': {id: 'customers.addChildCustomer', defaultMessage: 'Add Child Customer'},
  'customers.addChildren.error': {id: 'customers.addChildren.error', defaultMessage: 'Error adding children'},
  'customers.addChildren.success': {id: 'customers.addChildren.success', defaultMessage: 'Children added successfully'},
  'customers.addCustomerSupportUser': {id: 'customers.addCustomerSupportUser', defaultMessage: 'Sign in to customer as customer support user'},
  'customers.addCustomerSupportUser.description': {id: 'customers.addCustomerSupportUser.description', defaultMessage: "Clicking the button below will create a user for you in the customer's account and open Control Centre in a new tab."},
  'customers.addCustomerSupportUser.error': {id: 'customers.addCustomerSupportUser.error', defaultMessage: 'Error creating support user'},
  'customers.addNewCustomer': {id: 'customers.addNewCustomer', defaultMessage: 'Add New Customer'},
  'customers.addOptionalTemplate': {id: 'customers.addOptionalTemplate', defaultMessage: 'Add Optional Template'},
  'customers.applicationUrn': {id: 'customers.applicationUrn', defaultMessage: 'Application URN'},
  'customers.chooseTemplate': {id: 'customers.chooseTemplate', defaultMessage: 'Choose a Template'},
  'customers.create.error': {id: 'customers.create.error', defaultMessage: 'Customer Creation Failed'},
  'customers.create.success': {id: 'customers.create.success', defaultMessage: 'Customer Created Successfully'},
  'customers.customer': {id: 'customers.customer', defaultMessage: 'Customer'},
  'customers.customerId': {id: 'customers.customerId', defaultMessage: 'Customer ID'},
  'customers.editCustomer': {id: 'customers.editCustomer', defaultMessage: 'Edit Customer'},
  'customers.feature.enable': {id: 'customers.feature.enable', defaultMessage: 'Enable'},
  'customers.feature.enable.confirm.body': {
    defaultMessage: 'This will grant the customer with the following:',
    id: 'customers.feature.enable.confirm.body'
  },
  'customers.feature.enable.confirm.footer': {
    defaultMessage: "Note that this could have side-effects for the customer's account, and as a result <b><u>CANNOT</u> be undone</b>.",
    id: 'customers.feature.enable.confirm.footer'
  },
  'customers.feature.enable.confirm.header': {
    defaultMessage: 'Are you sure you want to enable feature <b>{feature}</b> for customer <b>{customer}</b>?',
    id: 'customers.feature.enable.confirm.header'
  },
  'customers.feature.enable.confirm.title': {
    defaultMessage: 'Enable feature: {feature}',
    id: 'customers.feature.enable.confirm.title'
  },
  'customers.feature.enabled': {id: 'customers.feature.enabled', defaultMessage: 'Enabled'},
  'customers.feature.noDescription': {id: 'customers.feature.noDescription', defaultMessage: 'No description'},
  'customers.feature.numSettings': {id: 'customers.feature.numSettings', defaultMessage: '{numSettings, plural, one {# setting} other {# settings}}'},
  'customers.features': {id: 'customers.features', defaultMessage: 'Features'},
  'customers.features.disabled': {id: 'customers.features.disabled', defaultMessage: 'Available Features'},
  'customers.features.empty': {id: 'customers.features.empty', defaultMessage: 'No features'},
  'customers.features.enabled': {id: 'customers.features.enabled', defaultMessage: 'Enabled Features'},
  'customers.featuresEnabled': {id: 'customers.featuresEnabled', defaultMessage: '{n, plural, =0 {no features} one {# feature} other {# features}} enabled'},
  'customers.firstUserEmail': {id: 'customers.firstUserEmail', defaultMessage: "First user's email address"},
  'customers.firstUserEmail.required': {id: 'customers.firstUserEmail.required', defaultMessage: "First user's email address is required"},
  'customers.firstUserEmail.validEmail': {id: 'customers.firstUserEmail.validEmail', defaultMessage: "First user's email address must be a valid email address"},
  'customers.firstUserName': {id: 'customers.firstUserName', defaultMessage: "First user's name"},
  'customers.firstUserName.nonBlank': {id: 'customers.firstUserName.nonBlank', defaultMessage: "First user's name must not be blank"},
  'customers.firstUserName.required': {id: 'customers.firstUserName.required', defaultMessage: "First user's name is required"},
  'customers.isSsoCustomer': {id: 'customers.isSsoCustomer', defaultMessage: 'Is SSO Customer'},
  'customers.isTemplate': {id: 'customers.isTemplate', defaultMessage: 'Is Template'},
  'customers.modules': {id: 'customers.modules', defaultMessage: 'Modules'},
  'customers.modules.required': {id: 'customers.modules.required', defaultMessage: 'At least one module must be selected'},
  'customers.name': {id: 'customers.name', defaultMessage: 'Name'},
  'customers.name.maxLength': {id: 'customers.name.maxLength', defaultMessage: 'Name can be at most {maxLength} characters long'},
  'customers.name.nonBlank': {id: 'customers.name.nonBlank', defaultMessage: 'Name must not be blank'},
  'customers.name.required': {id: 'customers.name.required', defaultMessage: 'Name is required'},
  'customers.noChildren': {id: 'customers.noChildren', defaultMessage: 'This customer has no children configured'},
  'customers.noParents': {id: 'customers.noParents', defaultMessage: 'This customer has no parents configured'},
  'customers.relationship': {id: 'customers.relationship', defaultMessage: 'Relationships'},
  'customers.relationship.child': {id: 'customers.relationship.child', defaultMessage: 'Child'},
  'customers.relationship.children': {id: 'customers.relationship.children', defaultMessage: 'Children'},
  'customers.relationship.parent': {id: 'customers.relationship.parent', defaultMessage: 'Parent'},
  'customers.relationship.parents': {id: 'customers.relationship.parents', defaultMessage: 'Parents'},
  'customers.removeChild': {id: 'customers.removeChild', defaultMessage: 'Remove Child'},
  'customers.samlMetadataUrl': {id: 'customers.samlMetadataUrl', defaultMessage: 'Enter URL of the Customer IDP SAML endpoint'},
  'customers.samlMetadataUrl.nonBlank': {id: 'customers.samlMetadataUrl.nonBlank', defaultMessage: 'Customer IDP SAML endpoint must not be blank'},
  'customers.samlMetadataUrl.required': {id: 'customers.samlMetadataUrl.required', defaultMessage: 'Customer IDP SAML endpoint is required'},
  'customers.samlReplyUrl': {id: 'customers.samlReplyUrl', defaultMessage: 'SAML Reply URL'},
  'customers.samlValues': {id: 'customers.samlValues', defaultMessage: 'The customer will need the following values in order to configure their identity provider (IDP):'},
  'customers.setting.name': {id: 'customers.setting.name', defaultMessage: 'Setting Name'},
  'customers.showFeatures': {id: 'customers.showFeatures', defaultMessage: 'Show Features'},
  'customers.showHandhelds': {id: 'customers.showHandhelds', defaultMessage: 'Show Handhelds'},
  'customers.showHubs': {id: 'customers.showHubs', defaultMessage: 'Show Hubs'},
  'customers.showLocations': {id: 'customers.showLocations', defaultMessage: 'Show Locations'},
  'customers.templating': {id: 'customers.templating', defaultMessage: 'Templating'},
  'customers.title': {id: 'customers.title', defaultMessage: 'Customers'},
  'customers.type': {id: 'customers.type', defaultMessage: 'Type'},
  'customers.update': {id: 'customers.update', defaultMessage: 'Update'},
  'customers.update.error': {id: 'customers.update.error', defaultMessage: 'Customer Update Failed'},
  'customers.update.success': {id: 'customers.update.success', defaultMessage: 'Customer Updated Successfully'}
});
